<template>
  <base-container>
    <template v-slot:header>
      <v-btn class="mx-2" @click="onBack" color="accent" :disabled="loading"
        >Voltar</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2"
        @click="validate"
        :disabled="!valid || loading || !editable"
        color="accent"
        >Salvar</v-btn
      >
    </template>
    <v-form ref="form" v-model="valid" lazy-validation="lazy-validation">
      <v-card :loading="loading">
        <v-toolbar flat="flat">
          <v-toolbar-title>Dados</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip color="#212121" bottom="bottom" v-if="!!animalId">
            <template v-slot:activator="{ on }">
              <v-btn
                class="mt-1 mx-3"
                @click="showLogs"
                icon="icon"
                key="history"
                large="large"
                text="text"
                v-on="on"
              >
                <v-icon>mdi-history</v-icon>
              </v-btn> </template
            ><span>Histórico do animal</span>
          </v-tooltip>
          <v-btn
            class="elevation-2"
            @click="editable = !editable"
            color="deep-orange lighten-1"
            dark="dark"
            fab="fab"
            small="small"
            v-if="!inserting"
          >
            <v-icon>{{ editable ? "mdi-close" : "mdi-pencil" }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="4">
              <v-text-field
                :disabled="!editable"
                :value="doc.owner.name"
                @click="searchAccount"
                @click:clear="clearOwner"
                clearable="clearable"
                label="Cliente"
                readonly="readonly"
                ref="owner"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field
                :disabled="!editable"
                :rules="nameRules"
                @keypress.enter="$refs.name.blur()"
                label="Nome do animal"
                ref="name"
                v-model="doc.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y="offset-y"
                max-width="290px"
                min-width="290px"
                readonly="readonly"
                v-model="menuBirth"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :disabled="!editable"
                    :rules="[v => !!v || 'Campo obrigarório']"
                    :value="birthFormatted"
                    append-icon="mdi-calendar-star"
                    label="Nascimento"
                    readonly="readonly"
                    ref="birth"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :max="$day().toJSON().substr(0, 10)"
                  @change="menuBirth = false"
                  locale="pt-br"
                  min="1980-01-01"
                  no-title="no-title"
                  v-model="doc.birth"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-autocomplete
                :disabled="!editable"
                :filter="textFilter"
                :items="speciesList"
                :rules="[v => !!v || 'Campo obrigarório']"
                label="Espécie"
                no-data-text="Nenhum resultado"
                ref="species"
                v-model="doc.species"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-autocomplete
                :disabled="!editable"
                :filter="breedFilter"
                :items="breedList"
                :rules="[v => !!v.nome || 'Campo obrigarório']"
                item-text="nome"
                label="Raça"
                no-data-text="Selecione uma espécie"
                ref="breed"
                return-object="return-object"
                v-model="doc.breed"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-autocomplete
                :disabled="!editable"
                :filter="textFilter"
                :items="sizeList"
                :rules="[v => !!v || 'Campo obrigarório']"
                label="Porte"
                no-data-text="Nenhum resultado"
                ref="size"
                v-model="doc.size"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-select
                :disabled="!editable"
                :items="['Macho', 'Fêmea']"
                :rules="[v => !!v || 'Campo obrigarório']"
                label="Sexo"
                ref="gender"
                v-model="doc.gender"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-autocomplete
                class="capitalize"
                :append-icon="searchColorValid ? 'mdi-plus' : 'mdi-menu-down'"
                :disabled="!editable"
                :filter="textFilter"
                :items="colors"
                :search-input.sync="searchColor"
                @click:append="searchColorValid && onAddColor(searchColor)"
                @keypress.enter="searchColorValid && onAddColor(searchColor)"
                hint="Para adicionar nova cor clique em '+' ou 'ENTER'"
                label="Cor"
                no-data-text="Nenhum resultado"
                persistent-hint="persistent-hint"
                ref="color"
                v-model="doc.color"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="$utils.capitalize(item)"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      @click.native="onRemoveColor(item)"
                      color="grey darken-1"
                      icon="icon"
                      text="text"
                    >
                      <v-icon>mdi-minus-circle</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-autocomplete
                :disabled="!editable"
                :filter="textFilter"
                :items="pelageList"
                label="Pelagem"
                no-data-text="Nenhum resultado"
                ref="pelage"
                v-model="doc.pelage"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field
                :disabled="!editable"
                @keypress.enter="$refs.weight.blur()"
                label="Peso (kg)"
                ref="weight"
                type="tel"
                v-mask="['#.##', '##.##']"
                v-model="doc.weight"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-select
                :disabled="!editable"
                :items="['Ativo', 'Inativo']"
                label="Status"
                ref="status"
                v-model="doc.status"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="8">
              <v-text-field
                :disabled="!editable"
                @keypress.enter="$refs.comments.blur()"
                label="Observações"
                ref="comments"
                v-model="doc.comments"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <collection-dialog
      :filters="accountsFilters"
      @data-selected="onSelectAccount"
      title="Selecione um cliente"
      type="accounts"
      v-if="collectionDialog"
      v-model="dialog"
    ></collection-dialog>
    <logs-dialog
      :animalId="animalId"
      title="Histórico do animal"
      v-model="dialogLogs"
    ></logs-dialog>
  </base-container>
</template>

<script>
import BaseContainer from "@/components/BaseContainer.vue";
import CollectionDialog from "@/components/CollectionDialog.vue";
import LogsDialog from "@/components/LogsDialog.vue";

import { especies, pelagens, portes } from "@zettabrasil/petshop-especies";
import { mask } from "vue-the-mask";
import { db } from "@/plugins/google/firebase";
import $store from "@/store";

const { COLLECTION_ANIMALS, COLLECTION_UTILS } = $store.getters,
  SOURCE = { source: "cache" };

export default {
  name: "animal-cadastro",
  components: { BaseContainer, CollectionDialog, LogsDialog },
  directives: { mask },
  props: ["type"],

  data: () => ({
    collectionDialog: false,
    dialog: false,
    dialogLogs: false,
    editable: false,
    loading: false,
    inserting: true,
    valid: true,
    docRef: null,
    // firestore doc
    doc: {
      birth: null,
      breed: { nome: null },
      color: null,
      comments: null,
      gender: null,
      name: null,
      owner: { name: null },
      pelage: null,
      size: null,
      species: null,
      status: "Ativo",
      weight: null,
    },
    nameRules: [
      (v) => !!v || "Campo obrigarório",
      (v) => (v && v.length > 1) || "Mínimo 2 caracteres",
    ],
    colors: [],
    speciesList: [],
    pelageList: [],
    sizeList: [],
    breedList: [],
    menuBirth: false,
    searchColor: null,

    accountsFilters: {
      role: "Cliente",
    },
    animalId: null,
  }),

  created() {
    this.inserting = this.type === "add";
  },

  mounted() {
    this.onStart();
  },

  watch: {
    "doc.species"(v) {
      this.breedList = especies[v];
    },

    "doc.breed"(v) {
      v.porte && (this.doc.size = v.porte);
    },
  },

  computed: {
    birthFormatted() {
      return this.doc.birth
        ? this.$day(this.doc.birth).format("DD/MM/YYYY")
        : "";
    },

    searchColorValid() {
      return this.searchColor && this.searchColor.length > 2;
    },
  },

  methods: {
    breedFilter(item, queryText) {
      const text = item.nome.toLowerCase();
      const searchText = queryText.toLowerCase();
      return text.indexOf(searchText) > -1;
    },

    clearOwner() {
      this.doc.owner = { name: "" };
    },

    async getColorsFromApi() {
      try {
        const colors = await db
          .collection(COLLECTION_UTILS)
          .doc("colors")
          .get(SOURCE);
        if (colors.exists) {
          return Object.keys(colors.data());
        }
        return [];
      } catch (e) {
        return [];
      }
    },

    getDataFromApi(docRef) {
      this.docRef = db.collection(COLLECTION_ANIMALS).doc(docRef);

      this.docRef
        .get(SOURCE)
        .then((snapshot) => {
          const newDoc = snapshot.data();
          newDoc.breed = { nome: newDoc.breed };
          newDoc.owner = newDoc.owner || { name: "" };
          this.doc = newDoc;
          this.animalId = snapshot.id;
        })
        .catch(() => {
          this.$snackbar.show({ title: "Erro ao buscar dados, sem conexão" });
        });
    },

    getStateData() {
      return {
        ...this.doc,
        breed: this.doc.breed.nome,
        owner: this.doc.owner.id ? this.doc.owner : null,
      };
    },

    onAddColor(v) {
      if (this.colors.indexOf(v.toLowerCase()) < 0) {
        this.colors.push(v.toLowerCase());
        this.setColorsToApi(this.colors);
      }
    },

    onBack() {
      this.$router.go(-1);
    },

    onSelectAccount(data) {
      if (data[0]) {
        this.doc.owner = { id: data[0].id, name: data[0].name };
      }
    },

    async onStart() {
      if (this.$route.params.docRef) {
        this.getDataFromApi(this.$route.params.docRef);
      } else {
        this.docRef = db.collection(COLLECTION_ANIMALS).doc();
        this.editable = true;
        setTimeout(() => {
          this.$refs.owner.focus();
        }, 250);
      }

      this.speciesList = Object.keys(especies);
      this.pelageList = pelagens;
      this.sizeList = portes;
      this.colors = await this.getColorsFromApi();
    },

    onRemoveColor(v) {
      const i = this.colors.indexOf(v.toLowerCase());
      if (i > -1) {
        this.colors.splice(i, 1);
        this.setColorsToApi(this.colors);
      }
    },

    setColorsToApi(l) {
      const doc = {};
      l.forEach((v) => (doc[v] = true));
      db.collection(COLLECTION_UTILS).doc("colors").set(doc);
    },

    showLogs() {
      if (this.animalId) {
        this.dialogLogs = true;
      }
    },

    textFilter(item, queryText) {
      const text = item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return text.indexOf(searchText) > -1;
    },

    searchAccount() {
      if (!this.collectionDialog) {
        this.collectionDialog = true;
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.dialog = true;
        }, 250);
      });
    },

    async saveToFirestore() {
      try {
        const doc = this.getStateData();
        doc.updatedAt = new Date().toJSON();

        if (this.inserting) {
          doc.createdAt = doc.updatedAt;
        }

        this.docRef.set(doc, { merge: true });
        this.editable = false;
        this.inserting = false;
        this.$snackbar.show({ title: "Dados salvos com sucesso" });
      } catch (e) {
        this.$snackbar.show({ title: "Erro ao salvar dados, sem conexão" });
      }
    },

    validate() {
      requestAnimationFrame(() => {
        if (this.$refs.form.validate()) {
          this.saveToFirestore();
        }
      });
    },
  },
};
</script>

<style>
.capitalize .v-select__slot input {
  text-transform: capitalize;
}
</style>
